<template>
  <div class="siteMap">
    <h1>
      <a href="https://compax.cc/#/">COMPAX</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/ACE1">ACE 1</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/ACE1Pro">ACE 1 Pro</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/MK215">MK215</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/ET7">ET 7</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/ACE2">ACE 2</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/D1">D1</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/Y1">Y1</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/9520Series">Agility 9520 Series</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/9533Series">Agility 9533 Series</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/customerCases">Customer Cases</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/platformServices">Platform Services</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/contactUs">Contact Us</a>
    </h1>
    <h1>
      <a href="https://compax.cc/#/desktopTerminals">Desktop Terminals</a>
    </h1>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.siteMap {
  background: #000;
  color: #fff;
  h1 {
    text-align: center;
    margin-top: 100px;
  }
}
</style>
